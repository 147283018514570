/*import config from './config';
const spauth = require('node-sp-auth');  
const requestprom = require('request-promise');  

// Site and User Creds  
const url = config.joblist.url;  
const clientId = config.joblist.clientId;  
const clientSecret = config.joblist.clientSecret;  

export default function joblist() {
  // Authenticate with  credentials - Get Access Token  
  spauth.getAuth(url, {          
      clientId:clientId,  
      clientSecret:clientSecret  
  })  
  .then(function(options){  
      // Access Token will be available on the options.headers variable
      var headers = options.headers;  
      headers['Accept'] = 'application/json;odata=verbose';  
      // Pull the SharePoint list items  
      requestprom.get({  
          url: url,  
          headers: headers,  
          json: true  
      }).then(function(rset){  
          const items = rset.d.results;  
          var jobs = [];
          items.forEach((e1) => {
            jobs.push ({
              "id":e1.Id || 0,
              "Job title": e1.Title || '',
              "Job location": e1.Location || '',
              "Job duration": e1.Duration || '',
              "Number of positions": e1.Positions + ((e1.Positions > 1) ? " openings" : " opening"),
              "Job description": e1.Description || '',
              "Education and experience required": e1.Experience || '',
              "Contact": e1.Contact || ''
            });
          }); 
          return jobs;
            
      });  
  })
  .catch((err) => {
      return [];
  });  
}

*/
module.exports = [
  {
    "id":5,
    "Job title": "Software Developer",
    "Job location": "Edison, NJ and unanticipated client sites across the US. Must be willing to travel and/or relocate domestically.",
    "Job duration": "Permanent position, Full time, Mon - Fri, 40 hours per week.",
    "Number of positions": "1 opening",
    "Job description": "Assist to design software applications & define test strategy. Develop the functionality using Python, Selenium, Azure, Robot Framework, REST API, QTP, Jira, J2EE, Shell scripting, Agile Methodologies. Assist to integrate software applications/customize changes to enhance operational efficiency. Test cases creation & automation of web services.",
    "Education and experience required": "Bachelor's degree in Computer Science or Engineering or IT or IS plus 2 years experience in job offered or as Analyst or Software Development. Prior work experience must include 2 years experience using Python, Selenium, Azure, Robot Framework, REST API, QTP, Jira, J2EE, Shell scripting, Agile Methodologies.",
    "Contact": "Mail resume to HR, Cloutics Inc, 2015 Lincoln Highway, Ste 140, Edison, NJ-08817. Ref: CL5.",
  },
 {
    "id":4,
    "Job title": "Application Developer",
    "Job location": "Edison, NJ and unanticipated client sites across the US. Must be willing to travel and/or relocate domestically.",
    "Job duration": "Permanent position, Full time, Mon - Fri, 40 hours per week.",
    "Number of positions": "3 openings",
    "Job description": "Assist to develop & analyze web Applications working on core functional modules integrating the design process with developed applications. Translate business requirement & assist in preparing technical design documents using BigData, Python, MySQL, JSON, AWS, Kafka, Spark, Hadoop, Scala. Write test cases, perform unit testing. Assist with Scrum related activities using Agile methodologies.",
    "Education and experience required": "Bachelor's degree in Computer Science or Engineering or IT or IS plus 6 months experience in job offered or as Analyst or Software Development. Prior work experience must include 6 months experience in BigData, Python, MySQL, JSON, AWS, Kafka, Spark, Hadoop, Scala, Agile methodologies.",
    "Contact": "Mail resume to HR, Cloutics Inc, 2015 Lincoln Highway, Ste 140, Edison, NJ-08817. Ref: CL4.",
  },
  {
    "id":3,
    "Job title": "SAP Functional Analyst, Sr.",
    "Job location": "Edison, NJ and unanticipated client sites across the US. Must be willing to travel and/or relocate domestically.",
    "Job duration": "Permanent position, Full time, Mon - Fri, 40 hours per week.",
    "Number of positions": "5 openings",
    "Job description": "Work on Automation Framework & financial data-driven metrics to build reporting environments. Assist to develop & implement core modules using SAP-FICO, SAP-VIM, SAP-PS, SAP-PP, SAP-BPC, SAP-ABAP, Informatica, TIBCO, Agile Methodologies integrating design process with developed applications. Convert Interface & flat file data using SAP-Retail. Analyze user needs & assist in preparing batch interface documents.",
    "Education and experience required": "Bachelor's degree or equivalent in Computer Science or IT or IS or Business Administration plus 5 years progressive experience in job offered or as Analyst or Functional Consultant. In lieu of Bachelor’s degree and 5 years experience will accept Master’s degree or equivalent in Computer Science or IT or IS or Business Administration plus 2 years experience in job offered or as Analyst or Functional Consultant. Prior work experience must include 2 years experience using SAP-FICO, SAP-VIM, SAP-PS, SAP-PP, SAP-BPC, SAP-ABAP, Informatica, TIBCO, Agile Methodologies, SAP-Retail.",
    "Contact": "Mail resume to HR, Cloutics Inc, 2015 Lincoln Highway, Ste 140, Edison, NJ-08817. Ref: CL3.",
  },
  {
    "id":2,
    "Job title": "Software Architect, Sr.",
    "Job location": "Edison, NJ and unanticipated client sites across the US. Must be willing to travel and/or relocate domestically.",
    "Job duration": "Permanent position, Full time, Mon - Fri, 40 hours per week.",
    "Number of positions": "5 openings",
    "Job description": "Assist to customize software Applications & develop new technical objects integrating design process with developed applications using Angular JS, SSRS, C++, Power BI, REST, ASP.Net, MS Azure, MongoDB, CSS, jQuery. Automate deployment procedures. Develop test cases by correcting problems including code compatibility. Evaluate functional specifications to create efficient technical design.",
    "Education and experience required": "Bachelor's degree in Computer Science or Engineering or IT or IS plus 5 years progressive experience in job offered or as Analyst or Engineer or Software Development. In lieu of Bachelor’s degree and 5 years experience will accept Master’s degree in Computer Science or Engineering or IT or IS plus 2 years experience in job offered or as Analyst or Engineer or Software Development. Prior work experience must include 2 years experience using Angular JS, SSRS, C++, Power BI, REST, ASP.Net, MS Azure, MongoDB, CSS, jQuery.",
    "Contact": "Mail resume to HR, Cloutics Inc, 2015 Lincoln Highway, Ste 140, Edison, NJ-08817. Ref: CL2.",
  },
  {
    "id":1,
    "Job title": "Software Developer",
    "Job location": "Edison, NJ and unanticipated client sites across the US. Must be willing to travel and/or relocate domestically.",
    "Job duration": "Permanent position, Full time, Mon - Fri, 40 hours per week.",
    "Number of positions": "5 openings",
    "Job description": "Analyze/develop software applications working on core functional modules to develop new technical objects integrating the design process with developed applications. Map current business process working on configuration changes to enhance operational efficiency & implement responsive web design using front-end framework for web development using JSON, AJAX, CSS, REST API, Bootstrap, NodeJS, React JS, Spring Boot, Spring Security, jQuery. Build prototype for Scheduling, Logging, Notification Service.",
    "Education and experience required" : "Master’s Degree in Computer Science or Computer Applications or Engineering or IT or IS plus 1 year experience in job offered or as Analyst or Engineer or Software Development. Prior work experience must include 1 year experience using JSON, AJAX, CSS, Rest API, Bootstrap, NODEJS, React JS, Spring Boot, Spring Security, JQuery.",
    "Contact": "Mail resume to HR, Cloutics Inc, 2015 Lincoln Highway, Ste 140, Edison, NJ-08817. Ref: CL1.",
  }
   
];
