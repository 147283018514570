import React from 'react';

import Layout from '../components/Layout';
import {TabTitleCard} from '../components/Card';

import JobListing from './job-listing';



const herobg = '#1c2841', herofcolor='#efefef';
const CodePage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>                        
                        <h1 style={{letterSpacing:'.1em'}}>We believe, a thoughtful & committed team 
                            can change the way, the world operates.</h1>
                </header>
        </div>
    
        
        <div className="inner row" >
            <>
                <TabTitleCard className='col-3' 
                            cardTitle="Career" cardLink='/career'/>
                <TabTitleCard className='col-3' 
                        cardTitle="Current openings" selected={true} />
            </>
            <div className='col-12' style={{margin:'.25em auto'}}>
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    Please email your profile to Hr At Cloutics Dot Com. We will get back to you as soon as we can.
                </p>
                
            </div>
        
            <JobListing />
            
            
        </div>
        <br /><br />
    </div>
  </Layout>
);

export default CodePage;
